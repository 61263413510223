<template>
  <div ref="scheduler"></div>
</template>

<script>
import moment from "moment";
import "dhtmlx-scheduler";
export default {
  name: "scheduler",
  props: {
    events: {
      type: Array,
      default() {
        return { events: [] };
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {};
  },
  watch: {
    events() {
      this.Scheduler();
    }
  },
  mounted() {
    this.Scheduler();
  },
  methods: {
    Scheduler ()
    {
      console.log( "this.$props.events", this.$props.events );
      let minDate = this.$props.events.reduce((a, b) => {
        let aTime = moment(a.start_date, "HH").hour();
        let bTime = moment(b.start_date, "HH").hour();

        aTime = isNaN(aTime) ? 23 : aTime;
        bTime = isNaN(bTime) ? 23 : bTime;

        return aTime < bTime ? a : b;
      });

      minDate = moment(minDate.start_date, "H").hour();
      console.log("minDate", minDate);

      scheduler.skin = "material";
      scheduler.config.readonly = true;
      scheduler.config.header = [
        "day",
        "week",
        "month",
        "date",
        "prev",
        "today",
        "next"
      ];
      scheduler.config.first_hour = minDate;
      scheduler.config.start_on_monday = false;
      // this.$_initSchedulerEvents();
      scheduler.init(this.$refs.scheduler, new Date(), "month");
      // console.log("this.$props.events", this.$props.events);
      scheduler.templates.event_bar_date = function() {
        return "";
      };
      scheduler.parse(this.$props.events);
      setTimeout(() => {
        scheduler.clearAll();
        scheduler.config_mark_now = true;
        scheduler.parse(this.$props.events);
      }, 600);
      // this.$_initSchedulerEvents();
      this.onClickEvent();
    },

    onClickEvent() {
      scheduler.attachEvent("onClick", function(id, e) {
        var obj = scheduler.getActionData(e);
        window.location.assign(`/join-session/${id}`);
        return true;
      });
    }

    // $_initSchedulerEvents: function() {
    //   if (!scheduler.$_eventsInitialized) {
    //     scheduler.attachEvent("onEventAdded", (id, ev) => {
    //       this.$emit("event-updated", id, "inserted", ev);
    //     });
    //     scheduler.attachEvent("onEventChanged", (id, ev) => {
    //       this.$emit("event-updated", id, "updated", ev);
    //     });
    //     scheduler.attachEvent("onEventDeleted", (id, ev) => {
    //       this.$emit("event-updated", id, "deleted");
    //     });
    //     scheduler.$_eventsInitialized = true;
    //   }
    // },
  }
};
</script>
