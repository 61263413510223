<template>
  <div id="calendar-event" v-loading.fullscreen.lock="loading">
    <defaultTemplate>
      <div class="pd-x-5 pd-t-5" v-if="badgeAll">
        <div class="is-flex flex-warp js-between ai-center">
          <div>
            <p class="font-20 font-weight-500">Calendar</p>
          </div>
          <div>
            <el-switch
              v-model="booking"
              active-color="#084E98"
              @change="myBooking()"
            >
            </el-switch>
            <span class="mg-l-6" :class="{ 'text-blue': booking }">{{
              booking ? "My Courses" : "All Courses"
            }}</span>
          </div>

          <!-- <div class="is-flex ai-center">
            <el-input
              class="w-100 mg-r-7"
              v-if="Search"
              placeholder="Search"
              v-model="searchText"
              @keyup.13.native="searchCalendar"
            ></el-input>
            <i
              class="font-14 font-weight-bold color-green-light el-icon-search"
              @click="Search = !Search"
            ></i>
          </div> -->
        </div>
        <!-- <pre>
          {{ badgeAll }}
        </pre> -->
        <Scheduler
          class="left-container"
          :loading="loading"
          :events="sortEvent(badgeAll)"
        ></Scheduler>
        <!-- <div class="right-container">
          <ul class="scheduler-messages">
            <li
              class="scheduler-message"
              v-for="(message, index) in messages"
              :key="index"
            >
              {{ message }}
            </li>
          </ul>
        </div> -->
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import moment from "moment";
import Scheduler from "@/components/Scheduler.vue";
import defaultTemplate from "@/template/default.vue";
export default {
  name: "Event",
  components: { Scheduler, defaultTemplate },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if (typeof this.$route.query.book != "undefined") {
      this.booking = true;
      this.myBooking();
    } else {
      this.getAllBadge();
    }
  },
  data() {
    return {
      loading: true,
      searchText: "",
      Search: false,
      badgeAll: null,
      eventsSort: null,
      badgeAllConst: null,
      messages: [],
      booking: false
    };
  },
  methods: {
    async myBooking() {
      this.loading = true;
      await this.getAllBadge();
      if (this.booking) {
        let getBook = [];
        let data = JSON.parse(JSON.stringify(this.badgeAllConst));
        data.forEach((item, index) => {
          let findIndex = item.book.findIndex(a => a.id == this.user.id);
          if (findIndex > -1) {
            getBook.push(item);
          }
        });
        this.badgeAll = getBook;
        setTimeout(() => {
          this.loading = false;
        }, 750);
      } else {
        this.badgeAll = JSON.parse(JSON.stringify(this.badgeAllConst));
        setTimeout(() => {
          this.loading = false;
        }, 750);
      }
    },
    async getAllBadge() {
      this.loading = true;
      await HTTP.get(`all/badge`)
        .then(res => {
          if (res.data.success) {
            this.badgeAll = res.data.obj;
            this.badgeAllConst = res.data.obj;
            this.loading = false;
          }
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    sortEvent(badge) {
      // console.log("this.$props.badgeAll", this.$props.badgeAll);
      let Sortdata = [];
      let color = "";
      badge.forEach(ev => {
        // console.log(ev.courseId);
        // let day = ev.sessionDate.split("/");
        // let day = moment(ev.sessionDate).format("DD");
        // console.log(
        //   moment(ev.sessionDate).format("YYYY"),
        //   moment(ev.sessionDate).format("MM"),
        //   moment(ev.sessionDate).format("DD")
        // );
        let time = ev.sessionStartTime.split(":");
        // console.log(time, "time");
        let timeEnd = ev.sessionEndTime.split(":");
        //  console.log(timeEnd, "timeEnd");
        let dateStart = new Date(
          moment(ev.sessionDate).format("YYYY"),
          moment(ev.sessionDate).format("MM") - 1,
          moment(ev.sessionDate).format("DD"),
          Number(time[0]),
          Number(time[1]),
          0,
          0
        );

        let dateEnd = new Date(
          moment(ev.sessionDate).format("YYYY"),
          moment(ev.sessionDate).format("MM") - 1,
          moment(ev.sessionDate).format("DD"),
          Number(timeEnd[0]),
          Number(timeEnd[1]),
          0,
          0
        );
        if (dateEnd < new Date()) {
          color = "#CCCCCC";
        } else if (ev.courseId == "IS") {
          color = "#084E98";
        } else if (ev.courseId == "UXP" || ev.courseId == "DMK") {
          color = "#008999";
        } else if (ev.courseId == "ETC") {
          color = "#5700A9";
        } else if (ev.courseId == "PBL") {
          color = "#9966CC";
        } else if (ev.courseId == "COM") {
          color = "#00D8A8";
        } else if (ev.courseId == "DTF" || ev.courseId == "INV") {
          color = "#66CCFF";
        } else if (ev.courseId == "DTP") {
          color = "#669999";
        } else if (
          ev.courseId == "ST1" ||
          ev.courseId == "ST2" ||
          ev.courseId == "ST3"
        ) {
          color = "#33CCCC";
        } else if (ev.courseId == "PCM") {
          color = "#33CC99";
        } else {
          color = "#339966";
        }

        let events = {
          id: ev._id,
          start_date: dateStart,
          end_date: dateEnd,
          text: `${ ev.courseId }.${ ev.skillTypeBadge}`,
          color: color
        };

        Sortdata.push(events);
      });
      // console.log("Sortdata", Sortdata);
      return Sortdata;
    },

    searchCalendar() {
      if (this.searchText != "") {
        // this.getSubjectLearning();

        console.log(this.searchText, "คำค้นหา");
        this.badgeAll = this.badgeAllConst.filter(
          fil =>
            fil.courseId.toLowerCase().indexOf(this.searchText.toLowerCase()) >
            -1
        );
        console.log(this.badgeAll, "ข้อมูลที่หามาได้");
        this.sortEvent(this.badgeAll);
      } else {
        this.getAllBadge();
      }
    }
    // addMessage(message) {
    //   this.messages.unshift(message);
    //   if (this.messages.length > 40) {
    //     this.messages.pop();
    //   }
    // },

    // logEventUpdate(id, mode, ev) {
    //   let text = ev && ev.text ? ` (${ev.text})` : "";
    //   let message = `Event ${mode}: ${id} ${text}`;
    //   this.addMessage(message);
    // },
  }
};
</script>

<style scoped>
/* html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
} */

.left-container {
  overflow: hidden;
  position: relative;
  height: 99vh;
  /* display: inline-block; */
  /* width: 90vw; */
  margin: 8px -15px;
  /* width: 970px; */
}
</style>
